import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchJobs(ctx, params) {
      return useJwt.fetchJobs(params).then(response => response);
    },
    getJobDetail(ctx, id) {
      return useJwt.getJobDetail(id).then(response => response);
    },
    reRunJob(ctx, id) {
      return useJwt.reRunJob(id).then(response => response);
    },
  },
};
