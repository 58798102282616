var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"hidden":!_vm.data || _vm.data.length === 0}},[_c('vue-good-table',{staticClass:"pt-2",attrs:{"columns":_vm.columns,"rows":_vm.data,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'success')?_c('span',[_c('b-badge',{staticClass:"font-small-1",attrs:{"variant":props.row.success ? 'light-primary' : 'light-danger'}},[_c('span',[_vm._v(_vm._s(props.row.success ? "Thành công" : "Thất bại"))])])],1):_c('span',[_c('span',{class:props.row.success ? 'text-primary' : 'text-danger'},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" Không có bản ghi nào! ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }