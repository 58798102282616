<template>
  <div :hidden="!data || data.length === 0">
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="data"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: isRepresent -->
        <span v-if="props.column.field === 'success'">
          <b-badge
            :variant="props.row.success ? 'light-primary' : 'light-danger'"
            class="font-small-1"
          >
            <span>{{ props.row.success ? "Thành công" : "Thất bại" }}</span>
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <span :class="props.row.success ? 'text-primary' : 'text-danger'">{{ props.formattedRow[props.column.field] }}</span>

        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BBadge,
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: this.rows,
    };
  },

  watch: {
    rows(val) {
      this.data = val;
    },
  },

  setup() {
    // Table Handlers
    const { t } = useI18nUtils();
    const columns = [
      {
        label: t('Hành động'),
        field: 'title',
        sortable: false,
      },
      {
        label: t('Mô tả'),
        field: 'description',
        sortable: false,
      },
      {
        label: t('Trạng thái'),
        field: 'success',
        sortable: false,
      },
    ];

    return {
      columns,
      t,
    };
  },
};
</script>
